<template>
  <div>
    <template v-if="loading">
      <v-overlay>
        <v-alert v-if="offline" type="error">
          Please check your internet connection,
          <v-btn text @click="fetchData">Reload</v-btn>
        </v-alert>
        <v-progress-circular
          v-else
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </template>
    <template v-if="!loading">
      <v-img
        :aspect-ratio="16 / 2"
        :src="require('@/assets/clients/media/banner/contact-page.png')"
      >
        <v-container class="align-center fill-height">
          <v-card-text class="display-1 white--text font-weight-bold"
            >About us</v-card-text
          >
        </v-container>
      </v-img>

      <section>
        <v-container>
          <v-responsive :aspect-ratio="16 / 5">
            <v-card-title
              :class="{
                headline: $vuetify.breakpoint.mdAndUp,
                title: $vuetify.breakpoint.smAndDown,
              }"
              class="font-weight-black text-uppercase primary--text"
              primary-title
              v-html="about.title"
              @dblclick="toggleEditMode('title')"
            ></v-card-title>

            <v-dialog :value="editMode == 'title'" persistent max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">Update</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="about.title"
                          name="title"
                          label="Title"
                        ></v-textarea>
                        <span>
                          Use
                          <code>&lt;br&gt;</code>for a line break
                        </span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="editMode = ''"
                    >Close</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="updateAbout(about)"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-card-text
              @dblclick="toggleEditMode('description')"
              v-html="about.description"
              class="body-1 gray--text"
            ></v-card-text>

            <v-dialog
              :value="editMode == 'description'"
              persistent
              max-width="600px"
            >
              <v-card>
                <v-card-title>
                  <span class="headline">Update</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <quill-editor
                          v-model="about.description"
                          :options="editorOption"
                        ></quill-editor>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="editMode = ''"
                    >Close</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="updateAbout(about)"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-responsive>
        </v-container>
      </section>
      <v-container class="px-0 pb-5 white" fluid>
        <Partners></Partners>
      </v-container>
    </template>
  </div>
</template>

<script>
// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import { mapGetters, mapActions } from "vuex";
import Partners from "./components/Partners";

export default {
  name: "About",
  components: {
    quillEditor,
    Partners,
  },
  data() {
    return {
      editMode: "",
      loading: false,
      offline: false,
      about: {},
      editorOption: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large"] }],
            ["bold", "italic"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters(["currentUserRole", "getAboutData"]),
  },
  methods: {
    ...mapActions(["update", "bind"]),

    updateAbout(data) {
      this.update({
        collection: "pages",
        doc: "about",
        data: {
          title: data.title,
          description: data.description,
        },
      })
        .then((response) => {
          this.editMode = "";
          // console.log("Res" + response.message);
          this.bind({
            collection: "pages",
            doc: "about",
            data: "about",
          });
        })
        .catch((error) => {
          // console.log("Err" + error);
        });
    },
    toggleEditMode(e) {
      if (this.currentUserRole == "admin") {
        this.editMode = e;
      }
    },
    fetchData() {
      if (this.getAboutData == null) {
        this.loading = true;
        this.bind({
          collection: "pages",
          doc: "about",
          data: "about",
        })
          .then((response) => {
            this.about = this.getAboutData;
            this.loading = false;
          })
          .catch((error) => {
            this.offline = true;
          });
      } else {
        this.about = this.getAboutData;
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
