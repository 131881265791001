<template>
  <v-container class="py-5">
    <v-row class="text-center">
      <v-col class="12 text-center" cols="12" md="12" lg>
        <v-card-text class="text-capitalize">
          <div class="mb-3 display-1">{{ title }}</div>
          <span class="caption">{{ description }}</span>
        </v-card-text>
      </v-col>
    </v-row>
    <v-hover v-if="currentUserRole === 'admin'" v-slot:default="{ hover }">
      <v-row class="mt-4">
        <template v-if="partnersLogoURL != 0">
          <v-col
            class="pa-2"
            cols="6"
            sm="3"
            md="3"
            v-for="(image, index) in partnersLogoURL"
            :key="index"
          >
            <v-hover v-slot:default="{ hover }">
              <v-img :src="image">
                <v-btn
                  v-if="hover && currentUserRole === 'admin'"
                  @click="deleteImage(image, index)"
                  small
                  color="error"
                  icon
                >
                  <v-icon>clear</v-icon>
                </v-btn>
              </v-img>
            </v-hover>
          </v-col>
        </template>
        <template v-else>
          <v-row>
            <v-col cols="12">
              <v-card height="100" flat>
                <v-card-text>Nothing to show</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <v-col v-if="hover && currentUserRole === 'admin'" class="pa-2" cols="6" sm="3" md="3">
          <v-sheet class="text-center" color="primary lighten-3" height="70">
            <v-btn @click="dialog = true" dark text>Add Logo</v-btn>
          </v-sheet>
        </v-col>
        <v-row>
          <v-dialog v-model="dialog" persistent max-width="290">
            <v-card align="center">
              <v-card-title>Upload Logo</v-card-title>
              <v-card-text>
                <v-file-input v-if="progress === 0" v-model="images"></v-file-input>
                <v-progress-circular
                  v-else
                  size="56"
                  color="primary"
                  v-model="progress"
                >{{ parseInt(progress) }}</v-progress-circular>
              </v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="green darken-1" text @click="dialog = false">Cancel</v-btn>
                <v-btn color="green darken-1" text @click="uploadImage()">Upload</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <Snackbar :snackbar="snackbar" />
      </v-row>
    </v-hover>
    <template v-else>
      <div>
        <VueSlickCarousel :slidesToShow="$vuetify.breakpoint.xs ? 1 : 3" v-bind="settings">
          <div v-for="(image, index) in partnersLogoURL" :key="index">
            <v-img :src="image"></v-img>
          </div>
        </VueSlickCarousel>
      </div>
    </template>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { mapGetters, mapActions } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";

// import style
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// import PartnersLogo from "./PartnersLogo";

export default {
  name: "PartnersLogo",
  components: {
    VueSlickCarousel
    // PartnersLogo
  },
  data() {
    return {
      dialog: false,
      editmode: true,
      description: "Partners of Quality Care",
      title: "Our Partners",
      progress: 0,
      snackbar: {
        isActive: false, //Activator
        x: "", // left or right or ""
        y: "", // top or bottom or ""
        color: "", // Any Color or ""
        mode: null, //vertical or multi-line or null
        // timeout: null, // set timeout Ex: 60000
        text: "" //Error Message
      },
      partnersLogoURL: [],
      images: null,
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 4000,
        cssEase: "linear"
      }
    };
  },
  computed: {
    ...mapGetters(["currentUserRole", "getAboutData"])
  },
  methods: {
    ...mapActions(["update"]),
    // uploadImage,
    uploadImage() {
      if (this.images) {
        // console.log(image.target.files[0])
        let file = this.images;
        let time = Date.now();
        // File or Blob named mountains.jpg
        let storageRef = firebase
          .storage()
          .ref("partners/" + time + "_" + file.name);

        // Create the file metadata
        let metadata = {
          contentType: "image/jpeg"
        };
        // Upload file and metadata to the object 'images/mountains.jpg'
        let uploadTask = storageRef.put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          snapshot => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            // console.log("Upload is " + this.progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log("Upload is running");
                break;
            }
          },
          error => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              // console.log('File available at', downloadURL);
              this.progress = 0;
              this.images = [];
              this.partnersLogoURL.push(downloadURL);
              this.update({
                collection: "pages",
                doc: "about",
                data: {
                  partners: this.partnersLogoURL
                }
              });
              this.snackbar.text = "Uploaded successfully";
              this.snackbar.color = "success";
              this.snackbar.isActive = true;
              this.dialog = false;
            });
          }
        );
      } else {
        this.snackbar.text = "Please select an image";
        this.snackbar.color = "warning";
        this.snackbar.isActive = true;
      }
    },
    // Delete image function
    deleteImage(imageUrl, index) {
      // Get Image URL and Reff in firebase storage
      let image = firebase.storage().refFromURL(imageUrl);
      // Delete the file from Firebase Storage
      image
        .delete()
        .then(() => {
          // console.log("File deleted successfully");
          this.partnersLogoURL.splice(index, 1); //Remove from Data: newProduct.images[]
          this.update({
            collection: "pages",
            doc: "about",
            data: {
              partners: this.partnersLogoURL
            }
          });
          this.snackbar.text = "Image deleted successfully";
          this.snackbar.color = "success";
          this.snackbar.isActive = true;
        })
        .catch(error => {
          this.snackbar.text = "Uh-oh, an error occurred!";
          this.snackbar.color = "error";
          this.snackbar.isActive = true;
          // console.log("Uh-oh, an error occurred!");
        });
    }
  },
  created() {
    this.partnersLogoURL = this.getAboutData.partners;
  }
};
</script>
